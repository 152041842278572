export class Studente {
  id?: string;
  nome?: string;
  cognome?: string;
  codiceInvito?: string;
  idInvito?: string;
  email?: string;
  provaCognitivaSvolta?: boolean;
  registrato?: boolean;
  emailsGenitori?: string[];
  sesso?: boolean;
  dataNascita?: Date;
  // per promozione - solo lato FE
  chiave?: string;
  classeId?: string;
  nomeCompleto?: string;
}

export class StudenteEdit {
  nome?: string;
  cognome?: string;
}
