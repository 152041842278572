import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Notifica, Params, TypeNotify } from '../../_model';
import { AuthService } from '../../_service';
import { NotificheFirestoreService } from '../../_service/notifiche-firestore.service';
import { Constants } from '../../_utility';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit {

  public showMobileMenu: boolean = false;
  public newNotify: Array<Notifica> = [];
  public oldNotify: Array<Notifica> = [];

  constructor(
    public authService: AuthService,
    public notificheService: NotificheFirestoreService,
    public translate: TranslateService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.authFinished$.subscribe({
      next: (finished: boolean) => {
        if (finished) {
          this.notificheService.newNotify.subscribe({
            next: (notifiche) => this.setNotify(this.sortNotifiche(notifiche), false)
          });
          this.notificheService.oldNotify.subscribe({
            next: (notifiche) => this.setNotify(this.sortNotifiche(notifiche), true)
          });
        }
      }
    });
  }

  sortNotifiche(notifiche: Array<Notifica>): Array<Notifica> {
    return notifiche.sort((a, b) => {
      if (new Date(a.date!) > new Date(b.date!)) {
        return -1;
      } else {
        return 1;
      }
    });
  }

  setNotify(notifiche: Array<Notifica>, isRead: boolean) {
    if (isRead) {
      this.oldNotify = notifiche;
    } else {
      this.newNotify = notifiche;
    }
  }

  readNotify() {
    if (this.newNotify.length > 0) {
      this.notificheService.readNotify();
    }
  }

  goToNotify(notifica: Notifica) {
    let routerLink!: Array<string>;
    let queryParams: Params = {};

    switch (notifica.type) {
      case TypeNotify.cambioReferenteRifiuta:
      case TypeNotify.associaDocente:
      case TypeNotify.cambioReferenteProposta:
      case TypeNotify.cambioReferenteRichiesta:
        routerLink = Constants.Routing.CLASSE.routerLink;
        queryParams = {
          id: notifica.params!['classeId']
        };
        break;
      case TypeNotify.consegnaCognitivo:
        routerLink = Constants.Routing.RISULTATI_QUESTIONARI_COGNITIVI.routerLink;
        queryParams = {
          id: notifica.params!['questionarioClasseId'],
          studenteId: notifica.params!['studenteId']
        };
        break;
      case TypeNotify.consegnaNonCognitivo:
        routerLink = Constants.Routing.RISULTATI_QUESTIONARI_NON_COGNITIVI.routerLink;
        queryParams = {
          id: notifica.params!['questionarioClasseId'],
          studenteId: notifica.params!['studenteId']
        };
        break;
      case TypeNotify.consegnaDiarioCognitivi:
        routerLink = Constants.Routing.RISULTATI_MODULO.routerLink;
        queryParams = {
          id: notifica.params!['moduloClasseId'],
          studenteId: notifica.params!['studenteId'],
          tab: 1
        };
        break
      case TypeNotify.rimozioneDocente:
        routerLink = Constants.Routing.DASHBOARD.routerLink;
        break;
      case TypeNotify.assegnazioneNonCognitivoReminder:
      case TypeNotify.assegnazioneCognitivoReminder:
        routerLink = Constants.Routing.ATTIVAZIONE_QUESTIONARIO.routerLink;
        queryParams = {
          type: "questionario",
          id: notifica.params!['questionarioId']
        };
        break;
      case TypeNotify.richiestaAssociazioneClasse:
        routerLink = Constants.Routing.CLASSE.routerLink;
        queryParams = {
          id: notifica.params!['classeId']
        };
        break;
      default:
        routerLink = Constants.Routing.DASHBOARD.routerLink;
        break;
    }

    this.router.navigate(routerLink, { queryParams: queryParams });
  }

  getIconByTypeNotify(type: TypeNotify) {
    let iconClass!: string;
    switch (type) {
      case TypeNotify.cambioReferenteRifiuta:
      case TypeNotify.rimozioneDocente:
      case TypeNotify.associaDocente:
      case TypeNotify.cambioReferenteProposta:
      case TypeNotify.richiestaAssociazioneClasse:
      case TypeNotify.cambioReferenteRichiesta:
        iconClass = 'icon-bookmark';
        break;
      case TypeNotify.assegnazioneCognitivoReminder:
      case TypeNotify.consegnaCognitivo:
        iconClass = 'icon-questionnaire';
        break;
      case TypeNotify.assegnazioneNonCognitivoReminder:
      case TypeNotify.consegnaNonCognitivo:
        iconClass = 'icon-lamp-blue';
        break;
      case TypeNotify.consegnaDiarioCognitivi:
        iconClass = 'icon-compass';
        break
      default:
        iconClass = 'icon-bell';
        break;
    }
    return iconClass;
  }

  showMenu() {
    this.showMobileMenu = true;
  }

  async logout() {
    await this.authService.logout();
  }

  sendEmail() {
    const email = 'support@futuri.education';
    const subject = 'Richiesta Supporto';
    document.location = "mailto:" + email + "?subject=" + subject;
  }
}
