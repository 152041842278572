export class Commento {
    idCommento?: string;
    testo?: string;
    docenteId?: string;
    nomeDocente?: string;
    cognomeDocente?: string;
    dataCreazione?: string;
    studenteId?: string;
    docenteClasseId?: string;
    sezioneClasse?: string;
    gradoClasse?: number;
    annoClasse?: number;
    avatarDocenteUrl?: string;
}