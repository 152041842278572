import { AfterViewInit, Component, HostListener } from '@angular/core';
import {
  Router,
  Event as RouterEvent,
  NavigationStart,
  NavigationEnd,
  NavigationCancel,
  NavigationError
} from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService, ClassroomAuthService } from './_service';
import { LocaleService } from './_utility';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {

  public hideMenu: boolean = false;
  public getScreenWidth: any;
  public getScreenHeight: any
  loading = true;

  constructor(
    private router: Router,
    private locale: LocaleService,
    private primengConfig: PrimeNGConfig,
    private classRoomAuthService: ClassroomAuthService,
    private authService: AuthService
  ) {
    this.locale.init();
    this.authService.user = null;
    this.primengConfig.ripple = true;
    // Router event per loading page
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    // non utilizzato
    this.getScreenWidth = window.innerWidth;
    if (this.getScreenWidth <= 1025) {
      this.hideMenu = true;
    }
  }

  // Shows and hides the loading spinner during RouterEvent changes
  navigationInterceptor(event: RouterEvent): void {
    window.scroll(0, 0);
    if (event instanceof NavigationStart) {
      this.loading = true;
    }
    if (event instanceof NavigationEnd) {
      this.loading = false;
    }
    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loading = false;
    }
    if (event instanceof NavigationError) {
      this.loading = false;
    }
  }

  ngAfterViewInit() {
    this.classRoomAuthService.gapiLoaded();
    this.classRoomAuthService.gisLoaded();
  }
}
