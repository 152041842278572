export class ScuolaTag {
    id!: string;
    testo!: string;
    tagPadreId!: string;
    punteggio!: number; //emoticon
    ordine?: any; //ordine scuole
    top?: boolean; //diamante
    select?: boolean;
    percentuale!: number;
    tagPadreNome?: string;
}