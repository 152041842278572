<nav class="main-nav">
  <div class="logo">
    <img src="assets/images/logo.svg" alt="Logo FUtuRI">
  </div>
  <ul class="menu">
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/dashboard">
        <span class="futuri-icon"><i class="icon-home"></i></span>
        <span class="text">{{"header.dashboard" | translate}}</span>
      </a>
      <div class="parallelepipedo"></div>
    </li>
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/classi">
        <span class="futuri-icon"><i class="icon-users-three"></i></span>
        <span class="text">{{"header.classi" | translate}}</span>
      </a>
      <div class="parallelepipedo"></div>
    </li>
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/questionari">
        <span class="futuri-icon"><i class="icon-question-circle"></i></span>
        <span class="text">{{"header.questionari" | translate}}</span>
      </a>
      <div class="parallelepipedo"></div>
    </li>
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/moduli">
        <span class="futuri-icon"><i class="icon-compass"></i></span>
        <span class="text">{{"header.moduli" | translate}}</span>
      </a>
      <div class="parallelepipedo"></div>
    </li>
    <li routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <a routerLink="/contenuti">
        <span class="futuri-icon"><i class="icon-video"></i></span>
        <span class="text">{{"header.contenuti" | translate}}</span>
      </a>
      <div class="parallelepipedo"></div>
    </li>
  </ul>
  <div class="extra-00"></div>
  <div class="extra-01"></div>
</nav>
