import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Classe, Commento, DettaglioQuestionarioStudente, DettaglioStudenteModuloAnno, Page, ProfiloStudente, ProfiloStudenteUpdate, ResponseGrafici, ScenariBanner, ScuolaTag } from '../_model';
import { Constants } from '../_utility';

@Injectable({
  providedIn: 'root'
})
export class StudenteService {

  constructor(private http: HttpClient) { }

  getProfiloStudente(studenteId: string): Observable<ProfiloStudente> {
    let url = `${environment.host}${Constants.StudenteService.PROFILO_STUDENTE(studenteId)}`;
    return this.http.get<ProfiloStudente>(url);
  }

  getQuestionariNonCognitiviStudenteGrafici(classeId: string, studenteId: string, esagono: boolean): Observable<ResponseGrafici> {
    let url = `${environment.host}${Constants.StudenteService.QUESTIONARI_NON_COGNITIVI_STUDENTE_GRAFICI(classeId, studenteId)}`;
    return this.http.get<ResponseGrafici>(url, { params: { 'esagono': esagono } });
  }

  getQuestionariCognitiviStudente(classeId: string, studenteId: string): Observable<Array<DettaglioQuestionarioStudente>> {
    let url = `${environment.host}${Constants.StudenteService.QUESTIONARI_COGNITIVI_STUDENTE(classeId, studenteId)}`;
    return this.http.get<Array<DettaglioQuestionarioStudente>>(url);
  }

  getModuliOrientativiStudente(classeId: string, studenteId: string): Observable<Array<DettaglioStudenteModuloAnno>> {
    let url = `${environment.host}${Constants.StudenteService.MODULI_ORIENTATIVI_STUDENTE(classeId, studenteId)}`;
    return this.http.get<Array<DettaglioStudenteModuloAnno>>(url);
  }

  getBacheca(id: string, page?: number, size?: number): Observable<Page<Commento>> {
    let url = `${environment.host}/studenti/${id}/commenti?page=${page}&size=${size}`;
    return this.http.get<Page<Commento>>(url);
  }

  setBachecaMessage(id: string, data: string): Observable<string> {
    let url = `${environment.host}/studenti/${id}/commenti`;
    return this.http.post<string>(url, data);
  }

  getClassiByStudenteId(studenteId: string): Observable<Array<Classe>> {
    let url = `${environment.host}/studenti/${studenteId}/classi`;
    return this.http.get<Array<Classe>>(url);
  }

  studenteProfiloUpdate(studenteId: string, body: ProfiloStudenteUpdate): Observable<any> {
    let url = `${environment.host}/studenti/${studenteId}`;
    return this.http.put<any>(url, body);
  }

  getScenariBanner(studenteId: string): Observable<ScenariBanner> {
    let url = `${environment.host}/studenti/${studenteId}/scenari/banner`;
    return this.http.get<ScenariBanner>(url);
  }

  getScenariDiSuccesso(studenteId: string, salvaGiudizio: boolean): Observable<ScuolaTag[]> {
    let url = `${environment.host}/studenti/${studenteId}/scenari/successo?salvaGiudizio=${salvaGiudizio}`;
    return this.http.get<Array<ScuolaTag>>(url);
  }

  saveGiudizioOrientativo(id: string, data: any): Observable<any> {
    let url = `${environment.host}/studenti/${id}/scenari/giudizio`;
    return this.http.put<string>(url, data);
  }

  inviaEmail(id: string): Observable<string> {
    let url = `${environment.host}/studenti/${id}/email-invito`;
    return this.http.post<string>(url, null);
  }
}
