import { AfterViewInit, Injectable } from '@angular/core';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { Banner, Notifica, TypeBanner } from '../_model';
import { Constants } from '../_utility';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificheFirestoreService {

  private _newNotify!: AngularFirestoreCollection<Notifica>;
  private _oldNotify!: AngularFirestoreCollection<Notifica>;

  constructor(private db: AngularFirestore, private authService: AuthService) { }

  get newNotify() {
    if (!this._newNotify) {
      this.getCollectionNewNotify();
    }
    return this._newNotify.valueChanges();
  }

  get oldNotify() {
    if (!this._oldNotify) {
      this.getCollectionOldNotify();
    }
    return this._oldNotify.valueChanges();
  }

  getBannerAttivita(classeId: string) {
    return this.getCollectionBannerAttivita(classeId).valueChanges();
  }

  getBannerDashboard() {
    return this.getCollectionBannerDashboard().valueChanges();
  }

  getCollectionNewNotify() {
    let id = this.authService.user.firebaseId;

    this._newNotify = this.db.collection(Constants.NotificheFirestoreService.BELL_PATH(id), ref => ref.where("show", "==", true));
  }

  getCollectionOldNotify() {
    let id = this.authService.user.firebaseId;

    this._oldNotify = this.db.collection(Constants.NotificheFirestoreService.BELL_PATH(id), ref => ref.where("show", "==", false));
  }

  getCollectionBannerAttivita(classeId: string): AngularFirestoreCollection<Banner> {
    let id = this.authService.user.firebaseId;

    return this.db.collection(Constants.NotificheFirestoreService.BANNER_ATTIVITA(id, classeId), ref => ref.orderBy("date", "desc"));
  }

  getCollectionBannerDashboard(): AngularFirestoreCollection<Banner> {
    let id = this.authService.user.firebaseId;

    return this.db.collection(Constants.NotificheFirestoreService.BANNER_DASHBOARD(id));
  }

  getCollectionBannerByTypes(classeId: string, types: Array<TypeBanner>): Observable<Banner[]> {
    let res: AngularFirestoreCollection<Banner>;
    let id = this.authService.user.firebaseId;
    res = this.db.collection(Constants.NotificheFirestoreService.BANNER_PATH(id, classeId), ref => ref.where("type", "in", types));
    return res.snapshotChanges().pipe(
      map(changes =>
        changes.map(c => {
          let res = c.payload.doc.data();
          res.id = c.payload.doc.id;
          return res;
        })
      )
    );
  }

  deleteBannerById(id: string, classeId: string): Promise<void> {
    let idFirebase = this.authService.user.firebaseId;
    return this.db.collection(Constants.NotificheFirestoreService.BANNER_PATH(idFirebase, classeId)).doc(id).delete();
  }

  disableShowBannerByPath(id: string, classeId: string): Promise<void> { 
    let idFirebase = this.authService.user.firebaseId;
    return this.db.collection(Constants.NotificheFirestoreService.BANNER_ATTIVITA(idFirebase, classeId)).doc(id).update({bellShow: false});
    // return this.db.doc(id).update({bellShow: false});
  }

  readNotify() {
    this._newNotify.ref.get().then(
      (notifiche) => {
        notifiche.forEach(async (notifica) => {
          await notifica.ref.update({ show: false });
        })
      }
    )
  }

}
