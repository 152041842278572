import { StatoDettaglioQuestionario } from "./";

export class DettaglioQuestionarioStudente {
    corsoNome!: string;
    materiaNome!: string;
    id!: string;
    questionarioClasseId?: string;
    idIstanzaStudMaieutical?: string;
    url?: string;
    punteggio!: number;
    media!: number;
    stato!: StatoDettaglioQuestionario;
}