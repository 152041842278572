import { Stato } from ".";

export class Istituto {
    id!: string;
    sorgente?: string;
    codice?: string;
    nome?: string;
    tipo?: string;
    // idComune?: string;
    // stato?: Stato;
}