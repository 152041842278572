import { Studente } from ".";

export class QuestionarioCognitivoRisposteResponse<T> {
    public titolo?: string;
    public annoScolastico?: number; 
    public classeAverage?: number;
    public percentualeAltreClasse?: number;
    public students?: Array<Studente>;
    public prove?: Array<T>;
}
