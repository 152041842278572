import { Scuola, StatoClasse, StatoClasseAttivabile, Studente } from ".";
import { Plesso } from "./Plesso";

export class Classe {
  id!: string;
  grado!: number;
  sezione?: string;
  numeroStudenti?: number;
  yearPercentage?: number;
  annoScolastico!: number;
  referente?: boolean;
  codiceClasse?: string;
  propostaReferente?: boolean;
  propostaReferenteCognome?: string;
  propostaReferenteNome?: string;
  plesso?: Plesso;
  generazioneClasseId?: string;
  docenteReferenteId?: string;
  certCompClasseId?: string;
  scuola?: Scuola;
  stato?: StatoClasse;
  // per configurazione classi
  selected?: boolean;
  // per promozione
  studenti?: Array<Studente>;
  //per panoramica classi
  questCognAssegnati?: number;
  questCognTotali?: number;
  questNonCognAssegnati?: number;
  questNonCognTotali?: number;
  moduliAssegnati?: number;
  moduliTotali?: number;
  attBannerId?: string;
}

export class ClasseQuestionario {
  scuola?: Scuola;
  classiAttivabiliAnno!: Array<ClassiAttivabiliAnno>;
}

export class ClassiAttivabiliAnno {
  grado?: number;
  classi!: Array<ClasseAttivabile>;
}

class ClasseAttivabile {
  grado?: number;
  id?: string;
  numStudenti?: number;
  sezione?: string;
  stato?: StatoClasseAttivabile;
}

// DA METTERE IN CONFIGURA CLASSE ALLA CHIAMATA getClassiByIstituto
export interface IstitutoClassiResponse {
  scuola: Scuola;
  classi: Array<Classe>;
}