import { TipologiaContenuto } from "./enum/TipologiaContenuto";

export class Contenuti {
  id?: string;
  nome?: string;
  descrizione?: string;
  link?: string;
  tipo?: TipologiaContenuto;
  tags?: Tag[];
  dataModifica?: Date;
}

export class ScuolaSuperiore {
  ambitoId?: string;
  descrizione?: string;
  id?: string;
  nome?: string;
  tipo?: string;
}

export class Tag{
  id?: string;
  testo?: string;
  selected?: boolean;
}

