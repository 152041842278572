<div id="main-nav">
  <app-main-nav></app-main-nav>
</div>
<div id="main-body">
  <app-header></app-header>
  <div id="main" class="docente">
    <router-outlet></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
<div class="clear"></div>
<div class="loading-overlay" *ngIf="loading">
  <div class="wrap-spinner">
    <div class="lds-facebook">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
<app-spinner></app-spinner>