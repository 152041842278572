import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import {
  Classe,
  ClasseRequest,
  DocenteCard,
  PanoramicaAnno,
  PanoramicaAnniClassi,
  Studente,
  Scelta,
  AssociaDocentiClasse,
  Docente,
  Questionario,
  QuestionarioResponse,
  ModuloClasse,
  IstitutoClassiResponse,
  PromuoviClasse,
  PromozioneRequest,
} from '../_model';

@Injectable({
  providedIn: 'root'
})
export class ClassiService {
  constructor(private http: HttpClient) { }

  getClasse(id: string) {
    let url = `${environment.host}/classi/${id}`
    return this.http.get<Classe>(url);
  }

  getClassiByIstituto(istitutoId: string): Observable<Array<IstitutoClassiResponse>> {
    let url = `${environment.host}/istituti/${istitutoId}/classi`
    return this.http.get<Array<IstitutoClassiResponse>>(url);
  }

  getClassiDocente(docenteId: string): Observable<Array<Classe>> {
    let url = `${environment.host}/docenti/${docenteId}/classi`
    return this.http.get<Array<Classe>>(url);
  }

  getClassiPromuovibili(istitutoId: string, flag: boolean): Observable<Array<Classe>> {
    let url = `${environment.host}/istituti/${istitutoId}/classi?promosse=${flag}`
    return this.http.get<Array<Classe>>(url);
  }

  getClassiWithStudenti(istitutoId: string, flag: boolean, grado: number): Observable<Array<Classe>> {
    let url = `${environment.host}/istituti/${istitutoId}/classi?promosse=${flag}&grado=${grado}`
    return this.http.get<Array<Classe>>(url);
  }

  promuoviClasse(scuolaId: string, body: PromozioneRequest): Observable<PromozioneRequest> {
    let url = `${environment.host}/scuole/${scuolaId}/classi`
    return this.http.put<any>(url, body);
  }

  associaDocentiClasse(classeId: string, docenteId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/docenti/${docenteId}`
    return this.http.post<any>(url, {});
  }

  associaDocentiMaterieClasse(classeId: string, docenteId: string, materieId: string[]): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/docenti/${docenteId}`
    return this.http.post<any>(url, materieId);
  }

  creaClasse(scuolaId: string, model: ClasseRequest): Observable<any> {
    let url = `${environment.host}/scuole/${scuolaId}/classi`
    return this.http.post<any>(url, model);
  }

  getDocenti(istitutoId: string, dettagli: boolean, query?: string): Observable<Array<Docente>> {
    let temp = !!query ? `&query=${query}` : ``;
    let url = `${environment.host}/istituti/${istitutoId}/docenti?dettagli=${dettagli}${temp}`
    return this.http.get<Array<Docente>>(url);
  }

  associaDocenti(classeId: string, model: AssociaDocentiClasse): Observable<AssociaDocentiClasse> {
    let url = `${environment.host}/classi/${classeId}/docenti`
    return this.http.post<any>(url, model);
  }

  getDocentiByClasseId(classeId: string): Observable<Array<DocenteCard>> {
    let url = `${environment.host}/classi/${classeId}/docenti`
    return this.http.get<Array<DocenteCard>>(url);
  }

  getPanoramicaAnniByClasseId(classeId: string): Observable<{ [key: string]: PanoramicaAnno }> {
    let url = `${environment.host}/classi/${classeId}/panoramica`
    return this.http.get<{ [key: string]: PanoramicaAnno }>(url);
    /* .pipe(map((res: PanoramicaAnniClassi) => {
      return res.classi ? res.classi : {};
    })); */
  }

  getStudentiByClasseId(classeId: string, registrati?: boolean): Observable<Array<Studente>> {
    let url = `${environment.host}/classi/${classeId}/studenti`;
    let params = new HttpParams();
    if (registrati) {
      params = params.append('registrati', registrati);
    }
    return this.http.get<Array<Studente>>(url, {params: params});
  }

  getStudentiByClasseIdAndRegistrazione(classeId: string, registrati: boolean): Observable<Array<Studente>> {
    let url = `${environment.host}/classi/${classeId}/studenti?registrati=${registrati}`
    return this.http.get<Array<Studente>>(url);
  }

  addStudentiXLSX(classeId: string, data: any): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/studenti/file`
    return this.http.post<any>(url, data);
  }

  addStudenti(classeId: string, data: any): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/studenti`
    return this.http.post<any>(url, data);
  }

  deleteClasse(classeId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}`
    return this.http.delete<any>(url);
  }

  deleteDocente(classeId: string, docenteId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/docenti/${docenteId}`
    return this.http.delete<any>(url);
  }

  deleteStudente(classeId: string, studenteId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/studente/${studenteId}`
    return this.http.delete<any>(url);
  }

  accettaRifiutaReferente(classeId: string, value: Scelta): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/proposta-referente`
    return this.http.put<any>(url, value);
  }

  nominaReferente(classeId: string, docenteId?: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/proposta-referente`
    return this.http.post<any>(url, docenteId);
  }

  getQuestionariByClasseId(classeId: string): Observable<QuestionarioResponse<Questionario>[]> {
    let url = `${environment.host}/classi/${classeId}/questionari`
    return this.http.get<QuestionarioResponse<Questionario>[]>(url);
  }

  getModuliByClasseId(classeId: string): Observable<ModuloClasse[]> {
    let url = `${environment.host}/classi/${classeId}/moduli`
    return this.http.get<ModuloClasse[]>(url);
  }

  associaDocenteClasse(classeId: string, docenteClasseId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/consiglio/${docenteClasseId}`
    return this.http.put<any>(url, {});
  }

  rifiutaDocenteClasse(classeId: string, docenteClasseId: string): Observable<any> {
    let url = `${environment.host}/classi/${classeId}/consiglio/${docenteClasseId}`
    return this.http.delete<any>(url, {});
  }
}
