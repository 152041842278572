import { TypeBanner } from "../_model";

export class Constants {

  public static DOCENTE_RADIX = 'docente';
  public static STUDENTE_RADIX = 'studente';

  public static TITLE_INFO = 'INFO!';
  public static TITLE_ERROR = 'ERRORE!';
  public static TITLE_WARNING = 'ATTENZIONE!';
  public static TITLE_SUCCESS = 'SUCCESSO!';
  public static TITLE_OFFLINE = 'IS OFFLINE!';

  static Regex = class {
    public static EMAIL = "^[^(!#$%&*+\/=?^`{|}~)]*$";
  }

  static Routing = class {
    public static ACCESS_DENIED = { label: 'Access_denied', path: 'access_denied', routerLink: ['/access_denied'] };
    public static LOGIN = { label: 'Login', path: 'login', routerLink: ['/login'] };
    public static DASHBOARD = { label: 'Dashboard', path: 'dashboard', routerLink: ['/dashboard'] };
    public static PROFILO = { label: 'Profilo', path: 'profilo', routerLink: ['/profilo'] };
    public static CLASSI = { label: 'Le mie classi', path: 'classi', routerLink: ['/classi'] };
    //public static CLASSE = { label: 'Classe', path: 'classe/:classe', routerLink: (classe: string) => { return ['/classe', classe] } };
    public static CLASSE = { label: 'Classe', path: 'classe', routerLink: ['/classe'] };
    public static STUDENTE = { label: 'Studente', path: 'studente', routerLink: ['/studente'] };
    public static QUESTIONARI = { label: 'Questionari', path: 'questionari', routerLink: ['/questionari'] };
    public static MODULI = { label: 'Moduli orientativi', path: 'moduli', routerLink: ['/moduli'] };
    public static CONTENUTI = { label: 'Contenuti', path: 'contenuti', routerLink: ['/contenuti'] };
    public static MODULO_DETTAGLIO = { label: 'Dettaglio Moduli', path: 'modulo', routerLink: ['/modulo'] };
    public static ATTIVAZIONE_QUESTIONARIO = { label: 'Attiva il questionario', path: 'attivazione_questionario', routerLink: ['/attivazione_questionario'] };
    public static RISULTATI_MODULO = { label: 'Risultati modulo', path: 'risultati_modulo', routerLink: ['/risultati_modulo'] };
    public static RISULTATI_QUESTIONARI_NON_COGNITIVI = { label: 'Risultati questionario', path: 'risultati-questionari-non-cognitivi', routerLink: ['/risultati-questionari-non-cognitivi'] };
    public static RISULTATI_QUESTIONARI_COGNITIVI = { label: 'Questionari Cognitivi', path: 'risultati-questionari-cognitivi', routerLink: ['/risultati-questionari-cognitivi'] };
    public static COMPETENZE_STUDENTE = { label: 'Competenze Studente', path: 'competenze-studente', routerLink: ['/competenze-studente'] };
    public static ESPRIMI_GIUDIZIO_ORIENTATIVO = { label: 'Esprimi Giudizio Orientativo', path: 'esprimi-giudizio-orientativo', routerLink: ['/esprimi-giudizio-orientativo'] };
    public static CERTIFICAZIONE_COMPETENZE = { label: 'Certificazione delle competenze', path: 'certificazione-competenze', routerLink: ['/certificazione-competenze'] };
  }

  static Auth = class {
    public static USER_KEY = "user";
    public static REMEMBER_KEY = "remember";
    public static ENV_KEY = "environment";
  }

  static Storager = class {
    public static USER_KEY = "user";
    public static MODULO_ORIENTATIVO = "moduloOrientativo";
  }

  static StudenteService = class {
    public static QUESTIONARI_NON_COGNITIVI_STUDENTE_GRAFICI = (classeId: string, studenteId: string) => { return `/classi/${classeId}/studenti/${studenteId}/dettaglio/questionari-non-cognitivi` };
    public static QUESTIONARI_COGNITIVI_STUDENTE = (classeId: string, studenteId: string) => { return `/classi/${classeId}/studenti/${studenteId}/dettaglio/questionari-cognitivi` };
    public static PROFILO_STUDENTE = (studenteId: string) => { return `/studenti/${studenteId}/profilo` };
    public static MODULI_ORIENTATIVI_STUDENTE = (classeId: string, studenteId: string) => { return `/classi/${classeId}/studenti/${studenteId}/dettaglio/moduli` };
  }

  static NotificheFirestoreService = class {
    public static BELL_PATH = (userId: string) => { return `/notifiche/${userId}/bell` };
    public static BANNER_PATH = (userId: string, classeId: string) => { return `/notifiche/${userId}/classi/${classeId}/banner` };
    public static BANNER_ATTIVITA = (userId: string, classeId: string) => { return `/banner-attivita/${userId}/classi/${classeId}/banner` };
    public static BANNER_DASHBOARD = (userId: string) => { return `/banner-attivita/${userId}/banner` };
  }

  static BANNER = class {
    public static ALLOW_TYPES: Array<TypeBanner> = [TypeBanner.referente_proposta, TypeBanner.referente_richiesta, TypeBanner.modulo, TypeBanner.competenze, TypeBanner.associazione_classe_banner];
  }

  static Grafici = class {
    public static DEFAULT_CHART_OPT: any = {
      plugins: {
        legend: { display: false },
        tooltip: {
          displayColors: false,
          callbacks: {
            label: function (context: any) {
              let label = context.dataset.label || '';
              return label;
            }
          },
          yAlign: 'bottom',
          xAlign: 'left',
          titleAlign: 'center',
          titleMarginBottom: 0
        }
      },
      scales: {
        r: {
          pointLabels: {
            display: false
          },
          ticks: {
            //display: false
            showLabelBackdrop: false,
            callback: function (value: any, index: any, ticks: any) {
              return '';
            }
          },
          angleLines: {
            color: '#CED6E2'
          },
          grid: {
            color: '#CED6E2',
          },
          min: 0,
          max: 5
          //beginAtZero: true
        }
      }
    };

    public static DEFAULT_RADAR_OPT = {
      backgroundColor: 'rgba(14, 180, 161, 0.5)',
      borderColor: '#0A8274',
      pointBackgroundColor: '#fff',
      pointBorderColor: '#0A8274',
      pointHoverBackgroundColor: '#0A8274',
      pointHoverBorderColor: '#0A8274',
      pointRadius: 6,
      pointHoverRadius: 6,
      borderWidth: 1
    };
  }

  static Email = class {
    public static SUPPORT = "mailto:support@futuri.education?Subject=";
    public static COMMUNITY = "mailto:community@futuri.education?Subject=";
  }
}
