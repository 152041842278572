import { StatusModulo } from "./enum/StatusModulo";

export class ModuloOrientativo {
  id?: string;
  macromoduloId?: string;
  macromoduloNome?: string;
  materiaId?: string;
  materiaNome?: string;
  nome?: string;
  grado?: number;
  filename?: string;
  minutiCompletamento?: number;
  dataStart?: string;
  dataDeadline?: string;
  status?: StatusModulo;
  dataCompletamento?: string;
}
