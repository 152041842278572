import { Classe } from "./Classe";
import { StatusQuestionario } from "./enum/StatusQuestionario";

export class QuestionarioResponse<T> {
  classeId?: string;
  dataCompletamento?: Array<number>;
  dataCreazione?: Array<number>;
  docenteId?: string;
  id?: string;
  istanzaMaieutical?: string;
  questionario?: T;
  stato?: StatusQuestionario;
  classe?: Classe;
  media?: number;
}
