import { Classe } from "./Classe";
import { Studente } from "./Studente";

export class ConfiguraClasse {
    submit?: string;
    classeSelezionata!: Classe;
    referente?: boolean;
    materie?: Array<string>;
    grado?: number;
    sezione?: string;
    classroom?: Classroom;
    students?: Array<Studente>;
    scuola?: string;
    // annoScolastico?: number;
}

interface Classroom {
    istituto?: any;
    classeId?: string;
    classe?: string;
    studenti?: Array<any>;
    id?: string;
}
