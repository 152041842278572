import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { ClasseQuestionario, ProvaCognitiva, Questionario, QuestionarioResponse, QuestionarioStudente, ResponseGrafici } from '../_model';
import { AttivaQuestionarioClasse, InfoQuestionario } from "../_model/AttivaQuestionarioClasse";

@Injectable({
  providedIn: 'root'
})
export class QuestionariService {
  constructor(private http: HttpClient) {
  }

  getQuestionari(tipologia: string): Observable<Array<Questionario>> {
    let url = `${environment.host}/questionari?tipologia=${tipologia}`;
    return this.http.get<Array<Questionario>>(url);
  }

  getClassiQuestionario(idQuestionario: string): Observable<Array<ClasseQuestionario>> {
    let url = `${environment.host}/questionari/${idQuestionario}/classi`;
    return this.http.get<Array<ClasseQuestionario>>(url);
  }

  activeClassiQuestionario(data: AttivaQuestionarioClasse): Observable<any> {
    let url = `${environment.host}/questionari`;
    return this.http.post<any>(url, data);
  }

  getInfoQuestionario(id: string, type: string): Observable<InfoQuestionario> {
    let url = "";
    if (type === "modulo") {
      url = `${environment.host}/moduli/${id}`;
    } else {
      url = `${environment.host}/questionari/${id}`;
    }
    return this.http.get<InfoQuestionario>(url);
  }



  getInfoQuestionarioByQuestionarioClasseId(questionarioClasseId: string): Observable<QuestionarioResponse<Questionario>> {
    let url = `${environment.host}/questionari-classi/${questionarioClasseId}`;
    return this.http.get<QuestionarioResponse<Questionario>>(url);
  }
  getStudentiByQuestionarioClasseId(questionarioClasseId: string): Observable<QuestionarioStudente[]> {
    let url = `${environment.host}/questionari-classi/${questionarioClasseId}/questionari-studenti`;
    return this.http.get<Array<QuestionarioStudente>>(url);
  }
  getResponseQuestionarioByQuestionarioStudenteId(questionarioStudenteId: string): Observable<ResponseGrafici | ProvaCognitiva> {
    let url = `${environment.host}/questionari-studenti/${questionarioStudenteId}/prova`;
    return this.http.get<ResponseGrafici | ProvaCognitiva>(url);
  }
}
