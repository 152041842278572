export class PanoramicaAnniClassi {
    public classi?: { [key: string]: PanoramicaAnno };
}

export class PanoramicaAnno {
    public classeId?: string;
    public sezione?: string;
    public grado?: number;
    public annoScolastico?: number;
    public yearPercentage?: number;
    public questCognAssegnati?: number;
    public questCognTotali?: number;
    public questNonCognAssegnati?: number;
    public questNonCognTotali?: number;
    public moduliAssegnati?: number;
    public moduliTotali?: number;
}