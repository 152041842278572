import { Classe } from ".";

export class ProfiloStudente {
  studenteId!: string;
  nome!: string;
  cognome!: string;
  istitutoId!: string;
  nomeIstituto!: string;
  comuneId?: string;
  nomeComune?: string;
  emailStudente!: string;
  avatarUrl?: string;
  classe!: Classe;
  emailsGenitori?: Array<string>;
}
