export class DettaglioStudenteModuloAnno {
    anno!: string;
    moduli!: DettaglioStudenteModulo[];
}

export class DettaglioStudenteModulo {
    id!: string;
    moduloClasseId!: string;
    stato!: StatoDettaglioModulo;
    moduloNome!: string;
    materiaNome!: string;
}

export enum StatoDettaglioModulo {
    non_svolta = 'NON_SVOLTA',
    non_visibile = 'NON_VISIBILE',
    svolta = 'SVOLTA'
}