import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from 'src/environments/environment';
import { MacroModulo, ModuloOrientativo, ClasseQuestionario, ModuloValutazioneDocente, RispostaElementoModulo, DomandeModuli, ModuloClasse, ModuloStudente, ModuloValutazioneDoc, ModuliResponse } from "../_model";
import { AttivaQuestionarioClasse } from "../_model/AttivaQuestionarioClasse";

@Injectable({
  providedIn: 'root'
})

export class ModuliOrientativiService {

  constructor(private http: HttpClient) { }

  getMacroModuli(): Observable<MacroModulo[]> {
    let url = `${environment.host}/macromoduli`
    return this.http.get<MacroModulo[]>(url);
  }

  getClassiModulo(macromoduloId: string, moduloId: string): Observable<Array<ClasseQuestionario>> {
    let url = `${environment.host}/macromoduli/${macromoduloId}/moduli/${moduloId}/classi`;
    return this.http.get<Array<ClasseQuestionario>>(url);
  }

  getModuliOrientativiByMacroModulo(macromoduloId: string): Observable<ModuliResponse[]> {
    let url = `${environment.host}/macromoduli/${macromoduloId}/moduli`
    return this.http.get<ModuliResponse[]>(url);
  }

  activeClassiModulo(macromoduloId: string, data: AttivaQuestionarioClasse): Observable<any> {
    let url = `${environment.host}/macromoduli/${macromoduloId}/moduli`;
    return this.http.post<any>(url, data);
  }

  getModuloDiario(moduloDiarioId: string): Observable<DomandeModuli> {
    let url = `${environment.host}/moduli-diari/${moduloDiarioId}`
    return this.http.get<DomandeModuli>(url);
  }

  getValutazioneModuloStudente(moduloValutazioneId: string): Observable<ModuloValutazioneDocente> {
    let url = `${environment.host}/moduli-valutazioni/${moduloValutazioneId}`
    return this.http.get<ModuloValutazioneDocente>(url);
  }

  // getModuloValutazionePreview(moduloId: string): Observable<ModuloValutazioneDocente> {
  //   let url = `${environment.host}/moduli/${moduloId}/preview-valutazione`
  //   return this.http.get<ModuloValutazioneDocente>(url);
  // }

  getModuloValutazionePreview(moduloStudenteId: string): Observable<ModuloValutazioneDocente> {
    let url = `${environment.host}/moduli-studenti/${moduloStudenteId}/preview-valutazione`
    return this.http.get<ModuloValutazioneDocente>(url);
  }

  getInfoModulo(moduloClasseId: string): Observable<ModuloClasse> {
    let url = `${environment.host}/moduli-classi/${moduloClasseId}`
    return this.http.get<ModuloClasse>(url);
  }

  getInfoStudentiModulo(moduloClasseId: string): Observable<ModuloStudente[]> {
    let url = `${environment.host}/moduli-classi/${moduloClasseId}/moduli-studenti`
    return this.http.get<ModuloStudente[]>(url);
  }

  postValutazioneModuliStudenti(moduloStudenteId: string, data: ModuloValutazioneDoc): Observable<any> {
    let url = `${environment.host}/moduli-studenti/${moduloStudenteId}/moduli-valutazioni`
    return this.http.post<any>(url, data);
  }

  sendModuloStudenti(moduloclasseid: string, data: any): Observable<any> {
    let url = `${environment.host}/moduli-classi/${moduloclasseid}`;
    return this.http.put<any>(url, data);
  }

  ChangeDateModuli(moduloclasseid: string, data: any): Observable<any> {
    let url = `${environment.host}/moduli-classi/${moduloclasseid}/data-deadline`;
    return this.http.put<any>(url, data);
  }

  DownloadPdf(id: string) {
    let url = `${environment.host}/moduli-valutazioni/${id}/pdf`;
    return this.http.get(url, { responseType: 'text' });
  }

}
