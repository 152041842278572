export class StudentiClassroom {
    courseId?: string;
    userId?: string;
    emailAddress?: string;
    profile!: ProfileClassroom;
    //custom-property
    selected?:boolean;
}

class ProfileClassroom {
    id?: string;
    name!: NomeClassroom;
    emailAddress?: string;
    permissions?: Array<PermissionClassroom>;
}

class NomeClassroom {
    givenName?: string;
    familyName?: string;
    fullName?: string;
}

class PermissionClassroom {
    permission?: string;
}