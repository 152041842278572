import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { ClasseClassroom, StudentiClassroom } from '../../_model';

declare var google: any;
declare var gapi: any;

@Injectable({
  providedIn: 'root',
})
export class ClassroomAuthService {

  private tokenClient!: any;

  /**
   * Callback after api.js is loaded.
   */
  public gapiLoaded() {
    gapi.load('client', this.intializeGapiClient);
  }

  /**
   * Callback after the API client is loaded. Loads the
   * discovery doc to initialize the API.
   */
  public async intializeGapiClient() {
    await gapi.client.init(
      {
        apiKey: env.classroom.API_KEY,
        discoveryDocs: env.classroom.DISCOVERY_DOCS
      });
  }

  /**
   * Callback after Google Identity Services are loaded.
   */
  public gisLoaded() {
    this.tokenClient = google.accounts.oauth2.initTokenClient({
      client_id: env.classroom.CLIENT_ID,
      scope: env.classroom.SCOPES,
      callback: ''
    });
  }

  /**
   *  Sign in the user upon button click.
   */
  public async signIn(funct: Function) {
    this.tokenClient.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        throw (resp);
      }
      //if (google.accounts.oauth2.hasGrantedAllScopes(resp, env.classroom.SCOPES.split(' '))) {
      funct();
      //}
    };

    this.tokenClient.requestAccessToken({ prompt: '' });
  }

  /**
   *  Sign out the user upon button click.
   */
  public handleSignoutClick() {
    const token = gapi.client.getToken();
    if (token !== null) {
      google.accounts.oauth2.revoke(token.access_token);
      gapi.client.setToken('');
    }
  }

  /**
   * Print the names of the first 10 courses the user has access to. If
   * no courses are found an appropriate message is printed.
   */
  public async listCourses(): Promise<Array<ClasseClassroom>> {
    let response = await gapi.client.classroom.courses.list({ teacherId: 'me' });
    let courses: Array<ClasseClassroom> = response.result.courses;
    if (!courses || courses.length == 0) {
      courses = [];
    }
    return courses;
  }

  public async listStudents(courseId: string): Promise<Array<StudentiClassroom>> {
    let response = await gapi.client.classroom.courses.students.list({ courseId: courseId });
    let students: Array<StudentiClassroom> = response.result.students;
    if (!students || students.length == 0) {
      students = [];
    }
    return students;
  }
}