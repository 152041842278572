import { BannerColor, Params, Title, TypeBanner } from ".";

export class Banner {
    id!: string;
    date?: string;
    image?: string;
    params?: Params;
    subtitle?: string;
    title?: Title;
    text?: Text;
    type?: TypeBanner;
    info?: any;
    color?: BannerColor;
    icon?: number;
}