import { Classe, ModuloOrientativo, StatusModulo, StatusModuloStudente } from "./index";

export class ModuloClasse {
  classe?: Classe;
  dataCompletamento?: string;
  dataCreazione?: string;
  docenteId?: string;
  id?: string;
  modulo?: ModuloOrientativo;
  stato?: StatusModulo;
  dataStart?: string;
  dataDeadline?: string;
  editable?: boolean;
  materiaDocente?: boolean;
}

export class ModuloStudente {
  dataDeadline?: string;
  dataStart?: string;
  grado?: number;
  id?: string;
  minuti?: number;
  moduloClasseId?: string;
  moduloNome?: string;
  studenteCognome?: string;
  moduloDiarioId?: string;
  moduloValutazioneId?: string;
  studenteNome?: string;
  stato?: StatusModuloStudente;
  studenteId?: string;
}
