import { Classe } from "./Classe";
import { TipologiaQuestionario } from "./enum/TipologiaQuestionario";

export class Questionario {
  id?: string;
  idEsterno?: string;
  nome?: string;
  corsoNome?: string;
  dataStart?: string;
  dataDeadline?: string;
  descrizione?: string;
  grado?: Array<number>;
  materiaNome?: string;
  minutiCompletamento?: number;
  tipologia?: TipologiaQuestionario;
  corsoId?: string;
  materiaId?: string;
}
