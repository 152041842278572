import { StatoLiberatoria } from "../enum/StatoLiberatoria";

export interface DocenteLiberatoria {
  id: string;
  stato: StatoLiberatoria;
  istitutoId: string;
  docenteId: string;
  blobId: string;
  downloadLink: string;
  filename: string;
  size: number;
}
