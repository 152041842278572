import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, Constants, RedirectGuard } from '../app/_utility';
import { RedirectLayoutComponent } from './_layout/redirect-layout/redirect-layout.component';
import { environment } from 'src/environments/environment';
import { TipologiaQuestionario } from './_model';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: Constants.Routing.ATTIVAZIONE_QUESTIONARIO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/attivazione-questionario/attivazione-questionario.module').then(mod => mod.AttivazioneQuestionarioModule) },
  { path: Constants.Routing.RISULTATI_MODULO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultati-modulo/risultati-modulo.module').then(mod => mod.RisultatiModuloModule) },
  { path: Constants.Routing.RISULTATI_QUESTIONARI_NON_COGNITIVI.path, data: { type: TipologiaQuestionario.nonCognitivo }, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultati-questionari/risultati-questionari.module').then(mod => mod.RisultatiQuestionariModule) },
  { path: Constants.Routing.RISULTATI_QUESTIONARI_COGNITIVI.path, data: { type: TipologiaQuestionario.cognitivo }, canActivate: [AuthGuard], loadChildren: () => import('./_layout/risultati-questionari/risultati-questionari.module').then(mod => mod.RisultatiQuestionariModule) },
  { path: Constants.Routing.DASHBOARD.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/dashboard/dashboard.module').then(mod => mod.DashboardModule) },
  { path: Constants.Routing.PROFILO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/profilo/profilo.module').then(mod => mod.ProfiloModule) },
  { path: Constants.Routing.CLASSI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/classi/classi.module').then(mod => mod.ClassiModule) },
  { path: Constants.Routing.CLASSE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/classe/classe.module').then(mod => mod.ClasseModule) },
  { path: Constants.Routing.STUDENTE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/studente/studente.module').then(mod => mod.StudenteModule) },
  { path: Constants.Routing.QUESTIONARI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/questionari/questionari.module').then(mod => mod.QuestionariModule) },
  { path: Constants.Routing.MODULO_DETTAGLIO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/moduli-dettaglio/moduli-dettaglio.module').then(mod => mod.ModuliDettaglioModule) },
  { path: Constants.Routing.MODULI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/moduli/moduli.module').then(mod => mod.ModuliModule) },
  { path: Constants.Routing.CONTENUTI.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/contenuti/contenuti.module').then(mod => mod.ContenutiModule) },
  { path: Constants.Routing.COMPETENZE_STUDENTE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/competenze-studente/competenze-studente.module').then(mod => mod.CompetenzeStudenteModule) },
  { path: Constants.Routing.ESPRIMI_GIUDIZIO_ORIENTATIVO.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/esprimi-giudizio-orientativo/esprimi-giudizio-orientativo.module').then(mod => mod.EsprimiGiudizioOrientativoModule) },
  { path: Constants.Routing.CERTIFICAZIONE_COMPETENZE.path, canActivate: [AuthGuard], loadChildren: () => import('./_layout/certificazione-competenze/certificazione-competenze.module').then(mod => mod.CertificazioneCompetenzeModule) },
  { path: Constants.Routing.LOGIN.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.futuri.redirect_url } },
  { path: Constants.Routing.ACCESS_DENIED.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.futuri.redirect_url + "/" + Constants.Routing.ACCESS_DENIED.path } },
  { path: '**', redirectTo: '/dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
