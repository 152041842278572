import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { firstValueFrom, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Constants, CookieStoragerService, LocalStoragerService, SessionStoragerService } from '../../_utility';
import { Type, User } from '../../_model';
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authFinished: Subject<boolean>;
  public authFinished$: Observable<boolean>;

  constructor(
    public afAuth: AngularFireAuth,
    public router: Router,
    public lStorager: LocalStoragerService,
    public sStorager: SessionStoragerService,
    public cStorager: CookieStoragerService,
    private http: HttpClient,
    private helperJwt: JwtHelperService) {
    this.authFinished = new Subject<boolean>();
    this.authFinished$ = this.authFinished.asObservable();
  }

  // Ritorna utente salvato nello storage
  get user(): User {
    const element = this.lStorager.getElement(Constants.Auth.USER_KEY);
    const user = element ? element : null;
    return user;
  }

  set user(user: User | null) {
    if (!user) {
      this.lStorager.removeElement(Constants.Auth.USER_KEY);
    } else {
      this.lStorager.setElement(Constants.Auth.USER_KEY, user);
    }
  }

  // Ritorna true quando utente è loggato e l'email è verifcato
  get isLoggedIn(): boolean {
    return this.user !== null && this.user.emailVerified !== false ? true : false;
  }

  // Ritorna il token o stringa vuota
  get token(): string {
    return this.user && this.user.token ? this.user.token : "";
  }

  set remember(flag: boolean) {
    if (flag) {
      this.lStorager.setElement(Constants.Auth.REMEMBER_KEY, true);
    } else {
      this.sStorager.setElement(Constants.Auth.REMEMBER_KEY, true);
    }
  }

  get remember(): boolean {
    let cookieRemember = this.cStorager.getElement(Constants.Auth.REMEMBER_KEY);
    if (cookieRemember) {
      this.remember = cookieRemember.remember === 'local';
      this.cStorager.removeElement(Constants.Auth.REMEMBER_KEY);
    }

    return this.lStorager.getElement(Constants.Auth.REMEMBER_KEY) || this.sStorager.getElement(Constants.Auth.REMEMBER_KEY);
  }

  get checkEnvironmentCookie(): boolean {
    let currentEnv = environment.env;
    let cookieEnvironment = this.cStorager.getElement(Constants.Auth.ENV_KEY);
    return currentEnv === cookieEnvironment;
  }

  private async setUserInfo(res: any) {
    let user = res.user;
    if (user) {
      user
      let currentUser: User = {
        token: await user.getIdToken()
      } as User;
      this.user = currentUser;

      let resProf: any = await firstValueFrom(this.profile());

      let firebaseUser = await this.afAuth.currentUser;

      currentUser.firebaseId = firebaseUser!.uid;
      currentUser.id = resProf.id;
      currentUser.name = resProf.name;
      currentUser.cognome = resProf.surname;
      currentUser.email = resProf.email;
      currentUser.authorities = resProf.authorities;
      currentUser.avatarUrl = user.photoURL;
      currentUser.isTeacher = currentUser.authorities?.findIndex(authorities => authorities.authority === Type.docente) !== -1;
      currentUser.isAdmin = currentUser.authorities?.findIndex(authorities => authorities.authority === Type.admin) !== -1;
      currentUser.docenteId = resProf.docenteId;
      currentUser.docenteTitoloDiStudioId = resProf.docenteTitoloDiStudioId;
      currentUser.istitutoId = resProf.istitutoId;
      currentUser.istitutoName = resProf.istitutoName;
      currentUser.liberatoriaCaricata = resProf.liberatoriaCaricata;

      this.user = currentUser;

      this.authFinished.next(true);
    }
  }

  // signIn con customToken
  async authenticate(customToken: string) {
    let persistence: "session" | "local" | "none" = "none";
    await this.afAuth.setPersistence(persistence);

    return this.afAuth
      .signInWithCustomToken(customToken)
      .then(async (res) => {
        await this.setUserInfo(res);
      });
  }

  async refreshToken(): Promise<boolean> {
    try {
      let currentUser = await this.afAuth.currentUser;
      let newToken = <string>await currentUser?.getIdToken(true);
      let refreshUser = this.user;
      refreshUser.token = newToken;
      this.user = refreshUser;
      return true;
    } catch {
      return false;
    }
  }

  goToLogin(): Observable<boolean> {
    this.router.navigate(Constants.Routing.LOGIN.routerLink);
    return new Observable<boolean>(o => o.next(false));
  }

  async logout(redirect = true) {
    try {
      this.lStorager.clearAll();
      this.sStorager.clearAll();
      await firstValueFrom(this.deleteCookie());
      this.cStorager.clearAll();
      await this.afAuth.signOut();
    } finally {
      this.user = null;
      if (redirect)
        this.goToLogin();
    }
  }

  profile(): Observable<Partial<User>> {
    let url = environment.host + `/users`
    return this.http.get(url);
  }

  isExpiredToken(): boolean {
    return this.helperJwt.isTokenExpired(this.token);
  }

  deleteCookie(): Observable<any> {
    let url = environment.host + `/auth/logout`;
    return this.http.get(url, { withCredentials: true });
  }

  async getCustomTokenFromCookie(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      try {
        let url = environment.host + `/auth/status`;
        let customToken: string = await firstValueFrom<string>(this.http.get(url, { withCredentials: true, responseType: 'text' }));
        if (customToken) {
          await this.authenticate(customToken);
        }
        resolve(true)
      } catch {
        await this.logout();
        reject();
      }
    });
    return promise;
  }
}
