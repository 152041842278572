import { TipologiaQuestionario } from ".";

export class QuestionarioStudente {
  id?: string;
  dataStart?: string;
  dataDeadline?: string;
  descrizione?: string;
  grado?: Array<number>;
  minutiCompletamento?: number;
  dataConsegna?: string;
  idIstanzaStudMaieutical?: string;
  questionarioClasseId?: string;
  stato?: StatusQuestionarioStudente;
  studenteId?: string;
  url?: string;
  corsoNome?: string;
  questionarioNome?: string;
  studenteCognome?: string;
  studenteNome?: string;
  tipologia?: TipologiaQuestionario
}

export enum StatusQuestionarioStudente {
  aperta = 'APERTA',
  consegnata = 'CONSEGNATA',
  non_consegnata = "NON_CONSEGNATA",
  cancellata = 'CANCELLATA' // Cancellazione logica al DB
}
