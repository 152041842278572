<footer id="footer-docente">
  <div class="container-fluid">
    <div class="row">
      <div class="d-sm-flex justify-content-sm-between align-items-center">
        <img alt="logo-footer" class="logo-footer" src="assets/images/logo-footer.svg" />
        <div class="d-sm-flex align-items-center wrap-rigth">
          <img alt="logo-fondazione-agnelli" class="logo-fondazione-agnelli"
            src="assets/images/logo-fondazione-agnelli.svg" />
          <img alt="logo-fondazione-de-agostini" class="logo-fondazione-de-agostini"
            src="assets/images/logo-fondazione-de-agostini.svg" />
        </div>
      </div>
    </div>
  </div>
</footer>
