import {Materia} from "../Materia";

export interface Docente {
  id?: string;
  utenteId?: string;
  firebaseId?: string;
  nome?: string;
  cognome?: string;
  email?: string;
  calssiFuturiId?: any;
  richiestaIscrizioneId?: string;
  materieId?: Array<string>;
  anzianitaServizio?: number;
  anzianitaServizioIstituto?: number;
  ruolo?: boolean;
  docenteTitoloDiStudioId?: string;
  docenteSpecializzazioniId?: Array<string>;
  liberatoriaCaricata?: boolean;
  istitutoId?: string;
  materie?: Array<Materia>;
}
