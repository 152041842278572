export class ClasseClassroom {
    id!: string;
    name!: string;
    descriptionHeading!: string;
    section!: string;
    alternateLink?: string;
    calendarId?: string;
    courseGroupEmail?: string;
    courseState?: string;
    creationTime?: string;
    enrollmentCode?: string;
    gradebookSettings?: GradebookSettings;
    guardiansEnabled?: boolean;
    ownerId?: string;
    room?: string;
    teacherFolder?: TeacherFolder;
    teacherGroupEmail?: string;
    updateTime?: string;
}

class GradebookSettings {
    calculationType?: string;
    displaySetting?: string;
}

class TeacherFolder {
    id?: string;
    title?: string;
    alternateLink?: string;
}