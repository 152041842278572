import { StatusModulo } from "./enum/StatusModulo";

export class ModuloResponse<T> {
  classeId?: string;
  dataCompletamento?: Array<number>;
  dataCreazione?: Array<number>;
  docenteId?: string;
  id?: string;
  modulo?: T;
  stato?: StatusModulo;
}
