import { Authority, Type } from "..";

export interface User {
    //Property firebase
    uid?: string;
    displayName: string;
    emailVerified?: boolean;
    token: string;

    //Custom property
    cognome?: string;
    stato?: any;
    avatarUrl?: any;
    isTeacher?: boolean;
    isAdmin?: boolean;
    nicknameChanged?: any;

    id: string;
    email?: string;
    firebaseId: string;
    name?: string;
    username?: string;
    authorities?: Array<Authority>;

     // Added properties from docente
     accountNonExpired: boolean;
     accountNonLocked: boolean;
     anzianitaServizio: number;
     anzianitaServizioIstituto: number;
     classiFuturiId: any;
     credentialsNonExpired: boolean;
     docente: boolean;
     docenteId: string;
     docenteSpecializzazioniId: Array<string>;
     docenteTitoloDiStudioId: string;
     enabled: boolean;
     issuer: string;
     istitutoId: string;
     istitutoName: string;
     liberatoriaCaricata: boolean;
     materieId: Array<string>;
     password: string;
     picture: string;
     richiestaIscrizioneId: string;
     ruoloDocente: boolean;
     surname: string;
     nomeComune: string;
     comuneId: string;
     classroomId: string;
}
