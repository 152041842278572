import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MainNavComponent } from './_components/main-nav/main-nav.component';
import { HeaderComponent } from './_components/header/header.component';
import { FooterComponent } from './_components/footer/footer.component';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthService, ClassroomAuthService } from './_service';
import { AuthGuard, ErrorInterceptor, JwtInterceptor } from './_utility';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { SidebarModule } from 'primeng/sidebar';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RippleModule } from 'primeng/ripple';
import { SpinnerModule } from './_components/spinner/spinner.module';
import { NotificheFirestoreService } from './_service/notifiche-firestore.service';

@NgModule({
  declarations: [
    AppComponent,
    MainNavComponent,
    HeaderComponent,
    FooterComponent,
  ],
  imports: [
    ButtonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'never' }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      extendedTimeOut: 6000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true,
      easeTime: 300
    }),
    OverlayPanelModule,
    SidebarModule,
    RippleModule,
    SpinnerModule
  ],
  providers: [
    AuthGuard,
    AuthService,
    ClassroomAuthService,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    NotificheFirestoreService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
