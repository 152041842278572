export enum TypeNotify {
    consegnaCognitivo = 'CONSEGNA_QUESTIONARIO_COGNITIVO',
    consegnaNonCognitivo = 'CONSEGNA_QUESTIONARIO_NON_COGNITIVO',
    associaDocente = 'ASSOCIA_DOCENTE',
    rimozioneDocente = 'RIMOZIONE_DOCENTE',
    cambioReferenteRichiesta = 'CAMBIO_REFERENTE_RICHIESTA',
    cambioReferenteProposta = 'CAMBIO_REFERENTE_PROPOSTA',
    consegnaDiarioCognitivi = 'CONSEGNA_DIARIO_COGNITIVO',
    cambioReferenteRifiuta = 'CAMBIO_REFERENTE_RIFIUTA',
    assegnazioneCognitivoReminder = 'ASSEGNAZIONE_QUESTIONARIO_COGNITIVO_REMINDER',
    assegnazioneNonCognitivoReminder = 'ASSEGNAZIONE_QUESTIONARIO_NON_COGNITIVO_REMINDER',
    richiestaAssociazioneClasse = 'RICHIESTA_ASSOCIAZIONE_CLASSE'
}
