export class ResponseGrafici {
    anno!: number;
    grafici!: Grafici;
}

export class Grafici {
    esagono?: Grafico | null;
    matematica?: Grafico | null;
    radar?: Grafico | null;
    impegno?: Grafico | null;
    personalita?: Grafico | null;
    motivazione?: Grafico | null;
    italiano?: Grafico | null;
    'radar-palline'?: Grafico | null;
    scuola_superiore_scelta?: Grafico | null;
    scuola_superiore_sensazione?: Grafico | null;
}

export class Grafico {
    id!: string;
    assi?: Asse[];
}

export class Asse {
    id!: string;
    valore!: any;
    parametri!: { [anno: number]: ParametriGrafici }
}

export class ParametriGrafici {
    min!: number;
    max!: number;
    cutOffLower!: number;
    cutOffUpper!: number;
}