import { ModuloOrientativo } from "./ModuloOrientativo";
import { Questionario } from "./Questionario";

export class Materia {
  id?: string;
  codice?: string;
  nome?: string;
  selected?: boolean;
}

export class MateriaConQuestionari extends Materia{
  questionari?: Questionario[];
}

export class MateriaConModuli extends Materia{
  moduli?: ModuloOrientativo[];
}
