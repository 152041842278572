<header id="second-nav">
  <button class="mobile-button" (click)="showMenu()">
    <i class="mobile-icon icon-menu"></i>
  </button>
  <div class="extra-form-00"></div>
  <div class="extra-form-01"></div>
  <div class="container-fluid">
    <div class="d-flex justify-content-end align-items-center flex-row">
      <div class="button-info p-2">
        <button class="header-button" type="button" (click)="sendEmail()">
          <span class="futuri-icon"><i class="icon-question-circle"></i></span>
        </button>
      </div>
      <div class="button-notify p-2">
        <button class="header-button" type="button" (click)="opNotifiche.toggle($event)">
          <div *ngIf="newNotify.length>0" class="new-notify"></div>
          <span class="futuri-icon bell" [ngClass]="{'active': newNotify.length>0}"><i class="icon-bell"></i></span>
        </button>
      </div>
      <div class="profile p-2">
        <div class="text">
          <div class="nome">{{authService.user?.name}} {{authService.user?.cognome | slice:0:1}}.</div>
          <div class="ruolo">{{"header.docente" | translate}}</div>
        </div>
        <div class="avatar" (click)="op.toggle($event)">
          <img [src]="authService.user?.avatarUrl" [alt]="authService.user?.name" class="avatar-icon">
        </div>
      </div>
    </div>
  </div>
</header>

<p-overlayPanel styleClass="overlay-profile" [appendTo]="'body'" #op>
  <ng-template pTemplate>
    <nav id="settings-nav">
      <ul>
        <li>
          <a (click)="op.hide()" routerLink="/profilo" [routerLinkActive]="['router-link-active']">
            <span><i class="pi pi-cog"></i></span>{{"header.settings" | translate}}</a>
        </li>
        <li><a (click)="logout()"><span><i class="pi pi-sign-out"></i></span>{{"header.logout" | translate}}</a></li>
      </ul>
    </nav>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel styleClass="overlay-notifiche" [appendTo]="'body'" #opNotifiche
  [style]="{'width': '370px','height':'550px'}" (onHide)="readNotify()">
  <ng-template pTemplate>
    <div id="notifiche-header">
      <h4>Notifiche</h4>
      <div class="wrap-notifiche">
        <div class="nuove">
          <span class="tipologia">Nuove</span>
          <ng-container *ngFor="let notifica of newNotify">
            <ng-container *ngTemplateOutlet="tempNotifica; context: {notifica:notifica}"></ng-container>
          </ng-container>
        </div>
        <div *ngIf="newNotify.length==0" class="no-notifiche">
          <span>Non ci sono nuove notifiche</span>
        </div>
        <div class="precedenti">
          <span class="tipologia">Precedenti</span>
          <ng-container *ngFor="let notifica of oldNotify">
            <ng-container *ngTemplateOutlet="tempNotifica; context: {notifica:notifica}"></ng-container>
          </ng-container>
        </div>
        <div *ngIf="oldNotify.length==0" class="no-notifiche">
          <span>Non ci sono nuove notifiche</span>
        </div>
      </div>
    </div>
    <ng-template #tempNotifica let-notifica="notifica">
      <div class="notifica d-flex justify-content-start align-items-center">
        <div class="wrap-icon">
          <div [class]="getIconByTypeNotify(notifica.type)"></div>
        </div>
        <div class="className">
          <p [innerHTML]="notifica.className"></p>
        </div>
        <div class="wrap-body">
          <div class="title" [innerHTML]="notifica.title[translate.currentLang]"></div>
          <div class="text" [innerHtml]="notifica.text[translate.currentLang]"></div>
        </div>
        <div class="new-notify" *ngIf="notifica.show"></div>
        <span class="icon icon-arrow-right" (click)="goToNotify(notifica); opNotifiche.hide()"></span>
      </div>
    </ng-template>
  </ng-template>
</p-overlayPanel>

<p-sidebar [(visible)]="showMobileMenu" [fullScreen]="true" [baseZIndex]="10000" [appendTo]="'body'">
  <div id="sidebarMobile">
    <div class="logo">
      <img src="assets/images/logo-esteso.svg" alt="Logo FUtuRI">
    </div>
    <ul class="menu">
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/dashboard">
          <span class="futuri-icon"><i class="icon-home"></i></span>
          <span class="text">{{"header.dashboard" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/classi">
          <span class="futuri-icon"><i class="icon-users-three"></i></span>
          <span class="text">{{"header.classi" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/questionari">
          <span class="futuri-icon"><i class="icon-question-circle"></i></span>
          <span class="text">{{"header.questionari" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/moduli">
          <span class="futuri-icon"><i class="icon-compass"></i></span>
          <span class="text">{{"header.moduli" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
      <li (click)="showMobileMenu=false" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
        <a routerLink="/contenuti">
          <span class="futuri-icon"><i class="icon-video"></i></span>
          <span class="text">{{"header.contenuti" | translate}}</span>
        </a>
        <div class="parallelepipedo"></div>
      </li>
    </ul>
    <div class="extra-00"></div>
    <div class="extra-01"></div>
  </div>
</p-sidebar>