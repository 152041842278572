import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Materia, Page } from '../_model';

@Injectable({
    providedIn: 'root'
})
export class MaterieService {
    constructor(private http: HttpClient) { }

    getMaterie(): Observable<Page<Materia>> {
      let url = `${environment.host}/public/materie`;
      return this.http.get<Page<Materia>>(url);
    }


    getMaterieByDocenteId(docenteId: string): Observable<Materia[]> {
      let url = `${environment.host}/docenti/${docenteId}/materie`
      return this.http.get<Materia[]>(url);
    }

    getScuoleByIstituti(istitutoId: string): Observable<Materia[]> {
      let url = `${environment.host}/public/istituti/${istitutoId}/scuole`
      return this.http.get<Materia[]>(url);
    }
}
