import { ModuloValutazioneRisposta } from "./ModuloValutazioneRisposta";

export class ContributiCompetenzaStudente {
    anno?: string;
    moduli?: Array<ModuliContributiCompetenzaStudente>;
}

export class ModuliContributiCompetenzaStudente {
    data?: string;
    id?: string;
    materiaNome?: string;
    moduloNome?: string;
    risposta?: number;
    risposte?: Array<ModuloValutazioneRisposta>
}