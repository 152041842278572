import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor(
    private http: HttpClient,
    private translate: TranslateService) { }

  getStudentiXLSX(): any {
    let url = `${environment.bucket}/templates/template_invito_studenti/template-invito-studenti.xlsx`
    return url;
  }

  getLiberatoriaPDF(): any {
    let url = `${environment.bucket}/templates/template_liberatoria/FUtuRI_accordo_contitolarita.pdf`
    return url;
  }

  getTerminiECondizioni(): any {
    let lang = this.translate.currentLang;
    let url = `${environment.bucket}/t&c/${lang}/FUtuRI_termini_servizio.pdf`
    return url;
  }

  getPrivacyPolicy(): any {
    let lang = this.translate.currentLang;
    let url = `${environment.futuri.redirect_url}/assets/documents/${lang}/FUtuRI_privacy_policy.pdf`
    return url;
  }

  getPropostaCircolareFamiglie(): any {
    let lang = this.translate.currentLang;
    let url = `${environment.bucket}/circolare_famiglie/${lang}/FUtuRI_proposta_circolare_per_famiglie.docx`
    return url;
  }
}
