import { Studente } from "./Studente";

export class ClasseRequest {
    grado?: number;
    sezione?: string;
    students?: Array<Studente>;
    materie?: Array<string>;
    classroomId?: string;
    // annoScolastico?: number;
}
