export enum StatusModuloStudente {
  aperto = 'APERTO',
  consegnato = 'CONSEGNATO',
  studente = 'STUDENTE',
  docente = 'DOCENTE',
  non_consegnato_studente = 'NON_CONSEGNATO_STUDENTE',
  non_consegnato_docente = 'NON_CONSEGNATO_DOCENTE',
  non_consegnato = 'NON_CONSEGNATO',
  cancellato = 'CANCELLATO'
}
